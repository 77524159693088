import axios from 'axios';
import ElementUI from 'element-ui';
import router from '@/router';  // 假设路由器定义在router.js中
import domainConfig from '@/utils/domain'

const BASE_URL = domainConfig.domainUrl;

class ApiServer {
    constructor() {
        this.request = axios.create({
            baseURL: BASE_URL,
            timeout: 30000,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            }
        });

        // 请求拦截器
        this.request.interceptors.request.use(config => {
            config.headers['Content-Type'] = 'application/json;charset=utf-8';
            let tokenName = localStorage.getItem('tokenName') ? JSON.parse(localStorage.getItem('tokenName')) : null;
            let tokenValue = localStorage.getItem('tokenValue') ? JSON.parse(localStorage.getItem('tokenValue')) : null;
            if (tokenName && tokenValue) {
                config.headers[tokenName] = tokenValue; // 设置请求头
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });

        // 响应拦截器
        this.request.interceptors.response.use(response => {
            let res = response.data;
            // 如果是返回的文件
            if (response.config.responseType === 'blob') {
                return res;
            }
            // 兼容服务端返回的字符串数据
            if (typeof res === 'string') {
                res = res ? JSON.parse(res) : res;
            }

            if (res.code === 401){
                //未登录,跳转到登录页面
                router.push("/login");
            }

            // 当权限验证不通过的时候给出提示
            // console.log("返回的数据:"+JSON.stringify(res))
            if (res.code !== 200) {
                ElementUI.Message({
                    message: res.desc,
                    type: 'error'
                });
            }
            return res;
        }, error => {
            // console.log('err' + error); // for debug
            return Promise.reject(error);
        });
    }

    get(url, params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            this.request
                .get(url, {
                    ...options,
                    params,
                })
                .then(response => {
                    resolve(response ? response : {});
                })
                .catch(error => {
                    this.handleError(error);
                    reject(error);
                });
        });
    }

    post(url, params = {}) {
        return new Promise((resolve, reject) => {
            this.request
                .post(url, params)
                .then(response => {
                    resolve(response ? response : {});
                })
                .catch(error => {
                    this.handleError(error);
                    reject(error);
                });
        });
    }

    handleError(error) {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                window.location.href = '/login'; // 重定向到登录页面
                ElementUI.Message({
                    message: 'Unauthorized. Please log in again.',
                    type: 'error'
                });
            } else {
                ElementUI.Message({
                    message: error.response.data.message || 'An error occurred.',
                    type: 'error'
                });
            }
        } else {
            ElementUI.Message({
                message: '网络错误',
                type: 'error'
            });
        }
    }
}

export default new ApiServer();
