import { getAdminMenuApi } from "@/api/system";

const actions = {
    getMenu({ commit }) {
        return new Promise((resolve) => {
            getAdminMenuApi().then(res => {
                if (res.code === 200) {
                    localStorage.setItem("menu", JSON.stringify(res.data));
                    commit('SET_MENU', res.data); // 将菜单数据提交给mutation
                    resolve(); // 成功获取菜单信息
                }
            });
        })
    }
}

export default {
    namespaced: true,
    actions
}
