import apiServer from '../../utils/request'

export function adminLoginApi(params = {}) {
    const url = `/admin/login`
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function adminLoginOutApi(params = {}) {
    const url = '/admin/loginOut';
    return apiServer.get(url, { params })
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function getAdminInfoApi(params = {}) {
    const url = '/admin/getAdminInfo';
    return apiServer.get(url, { params })
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function loadUserPageApi(params = {}){
    const url = `/user/loadUserPage`
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}


