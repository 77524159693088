// domain配置

export default {
    // domainUrl: 'http://localhost:8085/api/distribution',
    domainUrl: 'https://distribution-api.dreamboy.top/api/distribution',
    urls: {
        apiUrl: '/api/distribution',
        upload:{
            goodsImgUpload: '/upload/goodsImgUpload',
            goodsVideoUpload: '/upload/goodsVideoUpload',
        },
    }
};
