import apiServer from "@/utils/request";

export function getActionMenuIconsApi(params = {}) {
    const url = '/system/getActionMenuIcons';
    return apiServer.get(url, { params })
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function getAdminMenuApi(params = {}) {
    const url = '/module/getAdminMenu';
    return apiServer.get(url, { params })
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function loadModuleListApi(params = {}) {
    const url = '/module/loadModuleList';
    return apiServer.get(url, { params })
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function getModuleListApi(params = {}) {
    const url = `/module/getModuleList`;
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function saveOrUpdateModuleApi(params = {}) {
    const url = `/module/saveOrUpdateModule`;
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function loadRolePageApi(params = {}) {
    const url = `/role/loadRolePage`;
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function saveOrUpdateRoleApi(params = {}) {
    const url = `/role/saveOrUpdateRole`;
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function loadRoleMenuIdsApi(params = {}) {
    const url = `/role/loadRoleMenuIds/${params}`;
    return apiServer.get(url)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function bindRoleMenuApi(params = {}) {
    const url = `/role/bindRoleMenu`;
    return apiServer.post(url, params).then(res => {
        return Promise.resolve(res)
    })
}

export function delRoleByIdApi(params = {}) {
    const url = `/role/delRoleById/${params}`;
    return apiServer.get(url)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function batchDelRoleByIdsApi(params = {}) {
    const url = `/role/batchDelRoleByIds/${params}`;
    return apiServer.get(url)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function loadSystemServerApi() {
    const url = `/system/loadSystemServer`;
    return apiServer.get(url)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}

export function loadRoleListApi() {
    const url = `/role/loadRoleList`;
    return apiServer.get(url)
        .then(res => Promise.resolve(res))
        .catch(err => Promise.reject(err));
}
