import Vue from "vue";
import Vuex from "vuex";

import router, {resetRouter} from "@/router";
import user from "@/store/modules/user";
import {adminLoginOutApi} from "@/api/admin";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user
  },
  state:{
    currentPathName:''
  },
  mutations:{
    setPath (state){
      state.currentPathName = localStorage.getItem("currentPathName")
    },
    logout(){
      //清空缓存
      adminLoginOutApi().then(res => {
        localStorage.removeItem("admin")
        localStorage.removeItem("menus")
        router.push("/login")
        //重置路由
        resetRouter();
      });

    },
    login(){

    }

  },
  actions:{

  }
})

export default store