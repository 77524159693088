import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/index.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export const resetRouter = () => {
  const newRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });
  router.matcher = newRouter.matcher;
};

export const setRouters = () => {
  const currentRouteNames = router.getRoutes().map(v => v.name);
  if (!currentRouteNames.includes('Manage')) {
    const storeMenus = localStorage.getItem("menus");
    if (!storeMenus || storeMenus.length === 0 || storeMenus === "undefined" || storeMenus === "[]" || storeMenus === null || storeMenus === undefined) {
      localStorage.removeItem("menus");
      localStorage.removeItem("token");
      return;
    }
    const menus = JSON.parse(storeMenus);
    if (menus) {
      const manageRouter = {
        path: '/',
        name: 'Manage',
        component: () => import('@/components/Manage.vue'),
        redirect: "/home",
        children: []
      };
      menus.forEach(item => {
        if (item.modulePath) {
          let parentMenu = {
            path: item.modulePath.startsWith('/') ? item.modulePath.replace("/", "") : item.modulePath,
            name: item.moduleName,
            component: () => import('@/views' + item.modulePath + '/index.vue'),
            children: []
          };
          if (item.childrenList.length) {
            item.childrenList.forEach(subItem => {
              if (subItem.modulePath) {
                let childrenMenu = {
                  path: subItem.modulePath.startsWith('/') ? subItem.modulePath.replace("/", "") : subItem.modulePath,
                  name: subItem.moduleName,
                  component: () => import('@/views' + subItem.modulePath + '/index.vue')
                };
                if (childrenMenu){
                  parentMenu.children.push(childrenMenu)
                }
              }
            })
          }
          manageRouter.children.push(parentMenu);
        }
        if (item.childrenList.length) {
          item.childrenList.forEach(subItem => {
            if (subItem.modulePath) {
              let itemMenu = {
                path: subItem.modulePath.replace("/", ""),
                name: subItem.moduleName,
                component: () => import('@/views' + subItem.modulePath + '/index.vue')
              };
              if (itemMenu){
                manageRouter.children.push(itemMenu);
              }
            }
          });
        }

      });
      // console.log("此时的路由:"+JSON.stringify(manageRouter))
      router.addRoute(manageRouter); // 添加动态路由
    }
  }
};

setRouters();

router.beforeEach((to, from, next) => {
  localStorage.setItem("currentPathName", to.name); // 设置当前的路由名称
  store.commit("setPath"); // 触发 store 的数据更新

  if (!to.matched.length) {
    const storeMenus = localStorage.getItem("menus");
    if (storeMenus) {
      next("/404"); // 跳转到 404 页面
    } else {
      next("/login"); // 跳转到登录页面
    }
  } else {
    // 这里添加一个特殊的处理逻辑，如果已经登录但尝试访问登录页面，重定向到首页
    if (to.path === '/login' && localStorage.getItem("token")) {
      next('/');
    } else {
      next(); // 其他情况放行
    }
  }
});

export default router;
