import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/gloable.css';
import request from '@/utils/request';

Vue.config.productionTip = false
Vue.use(ElementUI, { size: "mini" });
Vue.prototype.request = request;
import domain from '../src/utils/domain';
// 将配置文件添加到 Vue 实例的原型上
Vue.prototype.$domain = domain;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
